/*
 * Globals
 */

/* Links */
a,
a:focus,
a:hover {
  color: white;
}

.card a {
  color:blue;
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: white;
  border: .05rem solid white;

}


/*
 * Base structure
 */

html,
body, html {
  background:
  linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
  linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
  linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
  linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
  linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
  linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
  background-color: #131313;
  background-size: 20px 20px;
  height: 100%;
  margin: 0;
}



.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
}
.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  padding:10px;
  width: 100%;
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  color: white;
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
}

p {
  color:white;
}

.logo-circle {
  border-radius: 50%;
  margin-right:auto;
  margin-left:auto;
  display: inline-block;
  width: 30%;
  min-width: 200px;
  padding: 2em 0px;
}

a, .link {
  color: lightskyblue;
  font-weight: bold;
}
a:hover, .link:hover {
  color:white;
  text-decoration: underline;
}
footer a {
  color: white;
}

.card {
  margin: 0px 20px;
}


.cover-container {
  max-width: 75em;
}

h1 {
  font-weight: bolder;
  text-shadow: 2px 1px maroon;
}
h2 {
  font-weight: bolder;
  text-shadow: 2px 1px maroon;
}
/*
 * Header
 */
.masthead {
  margin-bottom: 20px;
  background-color: rgba(128,0,0, 0.8);

}

.masthead-brand {
  margin-bottom: 0;
}

.nav-masthead a {
  padding: .25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead a:hover,
.nav-masthead a:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead a + a {
  margin-left: 1rem;
}

.nav-masthead .active {
    color:white;
    /* color: #fff; */
  border-bottom-color: white;
}

@media (min-width: 48em) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}


/*
 * Cover
 */
.cover {
  padding: 0 1.5rem;
}


/*
 * Footer
 */
.mastfoot {
  color: rgba(255, 255, 255, .5);
}