.social:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

a .social {
  color: white
}

.social {
  -webkit-transform: scale(0.8);
  /* Browser Variations: */

  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

/*
      Multicoloured Hover Variations
  */

#social-bandcamp:hover {
  color: #0687f5;
}

#social-spotify:hover {
  color: #1DB954;
}

#social-apple:hover {
  color: #fa586a;
}

#social-yt:hover {
  color: #ff0000;
}

#social-fb:hover {
  color: #3B5998;
}

#social-ig:hover {
  color: #d6249f;
}

#social-em:hover {
  color: #f39c12;
}